@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 621px 1fr;
    gap: 30px;
    align-items: flex-end;

    @media (max-width: $md1+px) {
        grid-template-columns: 550px 1fr;
    }

    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        align-items: unset;
    }

    @media (max-width: $md6+px) {
        gap: 24px;
    }

    .content {

        .title {}

        .text {
            margin: 32px 0 48px;
            max-width: 600px;

            @media (max-width: $md4+px) {
                margin: 30px 0 35px;
            }

            @media (max-width: $md6+px) {
                margin: 28px 0;
            }
        }

        .formWrap {
            position: relative;
            border-radius: 40px;
            padding: 60px;
            background: $bgcard;

            @media (max-width: $md3+px) {
                padding: 40px;
            }

            @media (max-width: $md5+px) {
                border-radius: 30px;
                padding: 30px;
            }

            @media (max-width: $md6+px) {
                border-radius: 20px;
                padding: 20px;
            }
        }

        .form {
            position: relative;

            &.blur {
                filter: blur(5px);
                opacity: 0;
            }

            .inputs {
                display: flex;
                flex-direction: column;
                gap: 32px;
                
                @media (max-width: $md6+px) {
                    gap: 20px;
                }

                .input {
                    height: 38px;
                    border-bottom: 1px solid $white;
                    background: transparent;
                    color: $white;
                    font-family: $inter;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 100%;

                    &::placeholder {
                        color: rgba($color: $white, $alpha: 0.7)
                    }

                    &.error {
                        border-bottom-color: red;
                    }

                    @media (max-width: $md6+px) {
                        font-size: 16px;
                        height: 36px;
                    }
                }
            }

            .btn {
                margin: 32px 0 12px;

                @media (max-width: $md6+px) {
                    margin-top: 20px;
                }
            }

            .agree {
                color: $grey;
                font-family: $inter;
                font-size: 12px;
                font-weight: 300;
                line-height: 120%;
                letter-spacing: 0.20px;

                @media (max-width: $md6+px) {
                    font-weight: 11px;
                }
            }
        }
    }

    .image {
        width: 100%;
        max-width: 879px;
        margin: 0 auto;

        @media (max-width: $md4+px) {
            max-width: 550px;
        }

        img {
            width: 100%;
        }
    }
}

.status {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    text-align: center;
    left: 0;
    top: 0;
    transform: scale(0.7);
    opacity: 0;
    pointer-events: none;
    transition: all .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px 20px;

    &Error,
    &Success {
        z-index: 3;
    }

    &Process {
        z-index: 2;
    }

    &Icon {
        width: 50px;
        height: 50px;
    }

    &Text {
        color: #fff;
        margin-top: 15px;
        letter-spacing: 0.5px;
    }


    &.active {
        transform: scale(1);
        opacity: 1;
        pointer-events: visible;

    }
}