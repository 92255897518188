@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        text-align: center;
    }
    
    .cards {
        margin-top: 48px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 120px;

        @media (max-width: $md1+px) {
            gap: 90px;
        }

        @media (max-width: $md3+px) {
            gap: 70px;
        }

        @media (max-width: $md4+px) {
            margin-top: 35px;
        }

        @media (max-width: $md6+px) {
            gap: 30px 0;
            margin-top: 28px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: center;
        }

        .image {
            height: 298px;

            @media (max-width: $md1+px) {
                height: 240px;
            }

            @media (max-width: $md3+px) {
                height: 200px;
            }

            @media (max-width: $md6+px) {
                height: 120px;
            }
        }
    }
}