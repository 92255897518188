@import "../../../styles/_var.scss";

.header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #050501;
    transition: all 0.1s;
    border-bottom: 1px solid rgba(255, 255, 255, 0.50);
    height: 95px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $md3+px) {
        height: 76px;
        display: block;
    }

    @media (max-width: $md4+px) {
        height: 68px;
    }

    @media (max-width: $md6+px) {
        height: 64px;
    }

    &::before {
        content: "";
        background: #050501;
        position: absolute;
        width: 100%;
        height: 0px;
        opacity: 0;
        z-index: -1;
    }

    .container {
        width: 100%;
    }

    &Active {
        height: 100%;

        &::before {
            opacity: 1;
            height: 100%;
        }

        .langs {
            display: none;
        }
    }

    .mobileMenu {
        opacity: 0;
        padding-top: 40px;
        pointer-events: none;
        transform: translateY(-400px);
        height: 0;

        @media (min-width: $md4+px) {
            display: none;
        }

        &Container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
        }

        .login {
            font-weight: 500;

            &:hover {
                color: $white;
            }
        }

        &Active {
            display: flex;
            transition: transform 0.5s;
            transform: translateY(0px);
            opacity: 1;
            pointer-events: visible;
        }
    }

    .headerRow {
        display: grid;
        grid-template-columns: 110px 1fr auto;
        gap: 60px;
        align-items: center;

        @media (max-width: $md3+px) {
            display: flex;
            justify-content: space-between;
            padding: 12px 0;
        }

        @media (max-width: $md6+px) {
            gap: 30px;
        }

        &Logo {
            @media (max-width: $md3+px) {
                width: 100px;
            }

            @media(max-width: $md6+px) {
                width: 90px;
            }

            a {
                width: 100%;
                height: 100%;
                display: block;
            }

            img {
                width: 100%;
            }
        }

        &Links {
            display: flex;
            gap: 40px;
            font-family: $inter;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;

            @media (max-width: $md3+px) {
                display: none;
            }

            .linkItem {
                position: relative;
                color: $grey;
                text-decoration: none;
                display: flex;
                align-items: center;
                gap: 5px;
                color: #313131;

                &:hover {
                    color: #000;
                }
            }

            .listWrap {
                position: relative;

                &:hover {
                    .subRoutes {
                        opacity: 1;
                        pointer-events: visible;
                    }

                    svg {
                        transform: rotate(180deg);
                    }
                }

                svg {
                    transition: all .3s;
                }

                .subRoutes {
                    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
                    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
                    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);

                    position: absolute;
                    background: #1F1D1D;
                    white-space: nowrap;
                    left: -10px;
                    top: 18px;
                    display: flex;
                    flex-direction: column;
                    gap: 0px;
                    padding-top: 0px;

                    opacity: 0;
                    pointer-events: none;

                    transition: all .3s;

                    a {
                        padding: 6px 10px;
                    }
                }
            }
        }

        &Right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 40px;

            @media (max-width: $md6+px) {
                gap: 30px;
            }

            .login {
                font-weight: 500;

                &:hover {
                    color: $white;
                }

                @media (max-width: $md4+px) {
                    display: none;
                }
            }

            .signup {
                @media (max-width: $md4+px) {
                    display: none;
                }
            }
            
            .langs {
                display: flex;
                align-items: center;
                
                .planetIcon {
                    width: 24px;
                }
            }

            .burger {
                cursor: pointer;
                display: flex;
                width: 32px;
                height: 14px;
                position: relative;

                @media (min-width: $md4+px) {
                    display: none;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: $lime;
                    border-radius: 10px;
                    transition: all 0.3s;
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: $grey;
                    border-radius: 10px;
                    transition: all 0.3s;
                }

                &Active {
                    &::before {
                        top: 6px;
                        transform: rotate(45deg);
                    }

                    &::after {
                        bottom: 6px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}

.optionStyle {
    display: flex;
    align-items: center;
    color: #fff;
    font-family: $inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
}