@import '../../../styles/var';

.wrap {
    position: relative;

    .head {
        display: grid;
        grid-template-columns: 600px minmax(300px, 720px);
        gap: 60px;

        @media (max-width: $md1+px) {
            grid-template-columns: 510px minmax(300px, 720px);
        }

        @media (max-width: $md3+px) {
            grid-template-columns: 500px 1fr;
        }

        @media (max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 35px;
        }

        @media (max-width: $md6+px) {
            gap: 28px;
        }

        .title {
            @media (max-width: $md6+px) {
                max-width: 330px;
            }
        }

        .text {}
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 4px;
        margin-top: 40px;

        @media (max-width: $md4+px) {
            margin-top: 35px;
        }

        @media (max-width: 850px) {
            display: flex;
            flex-direction: column;
        }

        @media (max-width: $md6+px) {
            margin-top: 28px;
        }

        .card {
            border-radius: 40px;
            background: $bgcard;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 28px;
            padding: 40px;
            transition: all .2s;

            @media (max-width: $md4+px) {
                padding: 30px;
            }

            @media (max-width: $md5+px) {
                border-radius: 30px;
            }

            @media (max-width: $md6+px) {
                border-radius: 20px;
                padding: 20px;
                gap: 16px;
            }

            &:hover {
                background: #30FBD5;

                .subtitle, .desc {
                    color: $dark;
                }

                .button {
                    background: $dark;

                    path {
                        fill: $white;
                    }
                }
            }

            .content {

                .subtitle {}

                .desc {
                    margin-top: 20px;

                    @media (max-width: $md6+px) {
                        margin-top: 12px;
                    }
                }
            }

            .button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 85px;
                cursor: pointer;
                transition: all .2s;
                width: 85px;
                height: 52px;
                border-radius: 100px;
                background: linear-gradient(90deg, $lime 0%, #30FBD5 100%);
                transition: all .2s;

                &:hover {
                    background: #fff;

                    path {
                        fill: $dark;
                    }
                }

                .arrow {
                    width: 41px;

                    path {
                        transition: all .2s;
                    }
                }
            }
        }
    }
}