@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        max-width: 1000px;
        margin: 12px auto 48px;
        text-align: center;

        @media (max-width: $md1+px) {
            max-width: 800px;
        }

        @media (max-width: $md4+px) {
            max-width: 630px;
        }

        @media (max-width: $md6+px) {
            margin-bottom: 28px;
        }
    }

    .cards {
        display: grid;
        grid-template-columns: 489px 577px 1fr;
        grid-template-rows: auto auto;
        gap: 4px;

        @media (max-width: $md1+px) {
            grid-template-columns: 400px 400px 1fr;
        }

        @media (max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 700px) {
            display: flex;
            flex-direction: column;
        }

        .card {
            position: relative;
            overflow: hidden;
            border-radius: 40px;
            background: #26262A;
            padding: 44px;

            @media (max-width: $md1+px) {
                padding: 40px;
            }

            @media (max-width: $md3+px) {
                grid-column: unset !important;
                grid-row: unset !important;
            }

            @media (max-width: $md5+px) {
                padding: 30px;
                border-radius: 30px;
            }

            @media (max-width: $md6+px) {
                padding: 20px;
                border-radius: 20px;
            }

            .content {
                position: relative;
                z-index: 5;

                .subtitle {}

                .text {
                    margin-top: 24px;
                }
            }

            .image {
                position: absolute;
                z-index: 2;
                bottom: 0;
                right: 0;

                @media (max-width: $md3+px) {
                    position: relative;
                }
            }

            &_01, &_02 {
                @media (max-width: $md3+px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 40px;
                }
            }

            &_01 {
                grid-column: 1/1;
                grid-row: 1/3;

                @media (max-width: $md3+px) {
                    padding-bottom: 0 !important;
                }

                .image {
                    width: 100%;

                    @media (max-width: $md6+px) {
                        width: calc(100% + 40px);
                        transform: translateX(-20px);
                    }
                }
            }

            &_02 {
                grid-column: 2/4;
                grid-row: 1/1;

                @media (max-width: $md3+px) {
                    padding-bottom: 0 !important;
                }

                .text {
                    max-width: 400px;

                    @media (max-width: $md3+px) {
                        max-width: 100%;
                    }
                }

                .image {
                    width: 100%;
                    max-width: 642px;

                    @media (max-width: $md1+px) {
                        max-width: 450px;
                    }

                    @media (max-width: $md6+px) {
                        width: calc(100% + 40px);
                        transform: translateX(-20px);
                    }
                }
            }

            &_03 {
                grid-column: 2/2;
                grid-row: 2/2;
            }

            &_04 {
                grid-column: 3/3;
                grid-row: 2/2;
                background: linear-gradient(90deg, #CBFB45 -8.47%, #30FBD5 107.92%);

                .content {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    gap: 24px;

                    .subtitle {
                        color: $dark;
                    }
                }
            }
        }
    }
}