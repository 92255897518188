@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 660px minmax(300px, 750px);
    align-items: center;
    justify-content: space-between;
    gap: 60px;
    margin-top: 195px;
    
    @media (max-width: $md1+px) {
        grid-template-columns: 580px minmax(300px, 750px);
    }

    @media (max-width: $md3+px) {
        grid-template-columns: 500px minmax(300px, 750px);
    }
    
    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        margin-top: 150px;
    }

    @media (max-width: $md6+px) {
        gap: 28px;
        margin-top: 100px;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {
            margin: 24px 0;

            @media (max-width: $md1+px) {
                max-width: 540px;
            }

            @media (max-width: $md6+px) {
                margin: 12px 0;
                max-width: 320px;

                br {
                    display: none;
                }
            }
        }

        .text {
            margin-bottom: 66px;

            @media (max-width: $md1+px) {
                margin-bottom: 50px;
            }

            @media (max-width: $md4+px) {
                margin-bottom: 45px;
            }

            @media (max-width: $md6+px) {
                margin-bottom: 28px;
            }
        }
    }

    .image {
        position: relative;
        z-index: 2;
        width: 100%;
        max-width: 750px;

        @media (max-width: $md4+px) {
            max-width: 500px;
        }

        img {
            width: 100%;
        }
    }
}