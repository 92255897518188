@import './var';

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.font-100 {
    color: $white;
    font-family: $inter;
    font-size: 100px;
    font-weight: 700;
    line-height: 110%;

    @media (max-width: $md1+px) {
        font-size: 80px;
    }

    @media (max-width: $md4+px) {
        font-size: 42px;
    }

    @media(max-width: $md6+px) {
        font-size: 36px;
    }

    span {
        color: $lime;
    }
}

.font-60 {
    color: $white;
    font-family: $inter;
    font-size: 60px;
    font-weight: 600;
    line-height: 130%;

    @media (max-width: $md1+px) {
        font-size: 50px;
    }

    @media (max-width: $md4+px) {
        font-size: 38px;
    }

    @media(max-width: $md6+px) {
        font-size: 32px;
    }

    span {
        color: $lime;
    }
}

.font-48 {
    color: $lime;
    font-family: $inter;
    font-size: 48px;
    font-weight: 700;
    line-height: 100%;

    @media(max-width: $md1+px) {
        font-size: 40px;
    }
}

.font-44 {
    color: $white;
    font-family: $inter;
    font-size: 44px;
    font-weight: 600;
    line-height: 120%;

    @media (max-width: $md1+px) {
        font-size: 34px;
    }

    @media (max-width: $md4+px) {
        font-size: 32px;
    }

    @media(max-width: $md6+px) {
        font-size: 24px;
    }
}

.font-40 {
    color: $white;
    font-family: $inter;
    font-size: 40px;
    font-weight: 600;
    line-height: 120%;

    @media (max-width: $md1+px) {
        font-size: 34px;
    }

    @media (max-width: $md4+px) {
        font-size: 28px;
    }

    @media(max-width: $md6+px) {
        font-size: 24px;
    }
}

.font-32 {
    color: $white;
    font-family: $inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0.6px;

    @media (max-width: $md4+px) {
        font-size: 28px;
        letter-spacing: 0.5px;
    }

    @media(max-width: $md6+px) {
        font-size: 24px;
    }
}

.font-28 {
    color: $white;
    font-size: 28px;
    font-weight: 600;
    line-height: 100%;

    @media (max-width: $md4+px) {
        font-size: 20px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-24 {
    color: $white;
    font-family: $inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 100%;

    @media (max-width: $md1+px) {
        font-size: 22px;
    }

    @media (max-width: $md4+px) {
        font-size: 20px;
    }

    @media(max-width: $md6+px) {
        font-size: 18px;
    }
}

.font-20 {
    color: $grey;
    font-family: $inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;

    @media (max-width: $md1+px) {
        font-size: 18px;
    }

    @media(max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-18 {
    color: $grey;
    font-family: $inter;
    font-size: 18px;
    font-weight: 300;
    line-height: 140%;

    @media (max-width: $md1+px) {
        font-size: 16px;
    }
}

.font-16 {
    font-family: $inter;
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;

    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}