@import '../../../styles/var';

.widget {
    width: 100%;
    height: 100%;
    background: $bgcard;
    border-radius: 40px;
    padding: 20px;

    @media (max-width: $md4+px) {
        padding: 10px;
    }

    @media (max-width: $md5+px) {
        border-radius: 30px;
    }

    @media (max-width: $md6+px) {
        border-radius: 20px;
        padding: 0;
    }
}