@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: 621px 1fr;
    gap: 30px;

    @media (max-width: $md1+px) {
        grid-template-columns: 500px 1fr;
    }

    @media (max-width: $md3+px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    @media (max-width: $md6+px) {
        gap: 30px;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 48px;

        @media (max-width: $md3+px) {
            gap: 35px;
        }

        @media (max-width: $md6+px) {
            gap: 28px;
        }

        .title {

        }

        .text {
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(3, auto);
        gap: 4px;

        @media (max-width: $md5+px) {
            display: flex;
            flex-direction: column;
        }

        .card {
            border-radius: 40px;
            background: $bgcard;
            padding: 40px 32px;
            display: flex;
            align-items: center;

            @media (max-width: $md1+px) {
                padding: 35px 20px;
            }

            @media (max-width: $md5+px) {
                border-radius: 30px;
            }

            @media (max-width: $md6+px) {
                border-radius: 20px;
                padding: 20px;
            }

            &_01, &_02, &_03 {

                .content {
                    display: grid;
                    grid-template-columns: 32px 1fr;
                    gap: 12px;

                    @media (max-width: $md1+px) {
                        grid-template-columns: 28px 1fr;
                    }

                    @media (max-width: $md6+px) {
                        grid-template-columns: 24px 1fr;
                        gap: 8px;
                    }
                }
            }
            
            &_01 {
                grid-column: 1/3;
                grid-row: 1/1;
            }

            &_02 {
                grid-column: 3/6;
                grid-row: 1/1;
            }

            &_03 {
                grid-column: 1/4;
                grid-row: 2/2;
            }

            &_04 {
                grid-column: 4/6;
                grid-row: 2/2;
                background-image: url('../../../assets/img/HomePage/about_company.avif');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;

                @media (max-width: $md5+px) {
                    height: 185px;
                }
            }

            &_05 {
                grid-column: 1/6;
                grid-row: 3/3;
                padding: 28px 0;
                text-transform: uppercase;

                @media (max-width: $md6+px) {
                    font-size: 20px;
                }
            }

            .check {
                width: 100%;
            }

            
            .desc {
                
            }

            .star {
                width: 40px;
                margin: 0 32px 0 24px;

                @media (max-width: $md4+px) {
                    width: 32px;
                    margin: 0 28px 0 20px;
                }

                @media (max-width: $md6+px) {
                    width: 24px;
                    margin: 0 16px 0 8px;
                }
            }
        }
    }
}