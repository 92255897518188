@import "../../../styles/var";

.footerWrap {
    background: $darkgreen;
    padding: 70px 0;

    @media (max-width: $md5+px) {
        padding: 50px 0;
    }

    @media (max-width: $md6+px) {
        padding: 36px 0;
    }
}

.footer {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .head {
        display: flex;
        align-items: center;
        gap: 100px;

        @media (max-width: $md4+px) {
            gap: 60px;
        }

        @media (max-width: $md5+px) {
            flex-direction: column;
            align-items: unset;
            gap: 30px;
        }

        .logo {
            width: 110px;

            @media (max-width: $md3+px) {
                width: 100px;
            }

            @media(max-width: $md6+px) {
                width: 90px;
            }
        }

        .rows {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .link {
            color: $white;

            &:hover {
                color: rgba($color: $white, $alpha: 0.7);
            }
        }
    }

    .address {
        color: $white;
    }

    .disclaimer {
        color: $white;
    }
}