@import '../../../styles/var';

.wrap {
    position: relative;

    .head {
        display: grid;
        grid-template-columns: auto 730px;
        justify-content: space-between;
        gap: 60px;

        @media (max-width: $md1+px) {
            grid-template-columns: auto 650px;
        }

        @media (max-width: $md3+px) {
            grid-template-columns: auto 1fr;
        }

        @media (max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 35px;
        }

        @media (max-width: $md6+px) {
            gap: 28px;
        }

        .title {

        }
        
        .text {

        }
    }   
    
    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 4px;
        margin-top: 40px;

        @media (max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
        }

        @media (max-width: $md5+px) {
            margin-top: 35px;
            display: flex;
            flex-direction: column;
        }

        @media (max-width: $md6+px) {
            margin-top: 28px;
        }

        .card {
            position: relative;
            overflow: hidden;
            border-radius: 40px;
            padding: 40px;
            background: $bgcard;

            @media (max-width: $md1+px) {
                padding: 35px;
            }

            @media (max-width: $md5+px) {
                border-radius: 30px;
                padding: 30px;
            }

            @media (max-width: $md6+px) {
                border-radius: 20px;
                padding: 20px;
            }

            .icon {
                height: 150px;

                @media (max-width: $md1+px) {
                    height: 120px;
                }

                @media (max-width: $md4+px) {
                    height: 100px;
                }

                @media (max-width: $md6+px) {
                    height: 65px;
                }
            }

            .subtitle {
                margin: 30px 0 28px;
                
                @media (max-width: $md3+px) {
                    margin: 24px 0 20px;
                }

                @media (max-width: $md6+px) {
                    margin: 15px 0 12px;
                }
            }

            .desc {
                
            }
        }
    }

    .widget {
        width: 100%;
        height: 722px;
        margin-top: 4px;

        @media (max-width: $md1+px) {
            height: 550px;
        }

        @media (max-width: $md6+px) {
            height: 400px;
        }
    }
}