.container {
    max-width: 1530px;
    margin: 0 auto;

    @media (max-width: $md1+px) {
        max-width: 1220px;
    }

    @media (max-width: $md3+px) {
        max-width: 100%;
        padding: 0 40px;
    }

    @media (max-width: 600px) {
        padding: 0 20px;
    }
}