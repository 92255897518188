@import "../../../styles/var";

.button {
    position: relative;
    display: inline-flex;
    cursor: pointer;
    transition: all 0.3s;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    text-align: center;

    border-radius: 25px;
    background: $lime;
    padding: 17px 42px;

    font-family: $inter;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    color: $dark;

    @media (max-width: $md6+px) {
        width: 100%;
        display: flex;
    }

    &:hover {
        background: #30FBD5;
    }

    &:active {
        transform: scale(0.98);
    }

    &.wfull {
        width: 100%;
        display: flex;
    }

    &.dark {
        background: $dark;
        color: $lime;

        &:hover {
            background: #26262A;
        }
    }
}