@import '../../../styles/var';

.wrap {
    background: $bgcard;
    padding: 140px 0;

    @media (max-width: $md4+px) {
        padding: 100px 0;
    }

    @media (max-width: $md6+px) {
        padding: 72px 0;
    }
}

.body {
    position: relative;

    .head {
        display: grid;
        grid-template-columns: 600px minmax(300px, 720px);
        gap: 60px;

        @media (max-width: $md1+px) {
            grid-template-columns: 510px minmax(300px, 720px);
        }

        @media (max-width: $md3+px) {
            grid-template-columns: 440px 1fr;
        }

        @media (max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 35px;
        }

        @media (max-width: $md6+px) {
            gap: 28px;
        }

        .title {
            @media (max-width: $md6+px) {
                max-width: 330px;
            }
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 4px;
        margin-top: 60px;

        @media (max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: $md4+px) {
            margin-top: 35px;
        }

        @media (max-width: $md5+px) {
            display: flex;
            flex-direction: column;
        }

        @media (max-width: $md6+px) {
            margin-top: 28px;
        }

        .card {
            background: $dark;
            border-radius: 40px;
            padding: 40px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 20px;

            @media (max-width: $md1+px) {
                padding: 30px;
            }

            @media (max-width: $md5+px) {
                border-radius: 30px;
            }

            @media (max-width: $md6+px) {
                border-radius: 20px;
                padding: 20px;
                gap: 12px;
            }

            .headingWrap {
                .heading {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    align-items: center;
                    gap: 20px;
    
                    .info {
    
                        .num {
                            color: $white;
                            font-family: $inter;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 100%;
                        }
    
                        .subtitle {
                            color: $white;
                            font-weight: 600;
                            margin-top: 6px;

                            @media (max-width: $md6+px) {
                                margin-top: 2px;
                            }
                        }
                    }
    
                    .price {
                        background: var(--LINER, linear-gradient(90deg, #CBFB45 -8.47%, #30FBD5 107.92%));
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        display: inline-flex;

                        @media (max-width: $md6+px) {
                            font-size: 28px;
                        }
                    }
                }

                .desc {
                    margin-top: 28px;

                    @media (max-width: $md6+px) {
                        margin-top: 20px;
                    }
                }
            }

            .content {
                padding-top: 20px;
                border-top: 1px solid rgba($color: $white, $alpha: 0.33);

                .rows {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    margin-bottom: 48px;

                    @media (max-width: $md4+px) {
                        margin-bottom: 35px;
                        gap: 16px;
                    }

                    @media (max-width: $md6+px) {
                        margin-bottom: 24px;
                    }

                    .row {
                        display: grid;
                        grid-template-columns: 24px 1fr;
                        align-items: center;
                        gap: 8px;

                        @media (max-width: $md6+px) {
                            grid-template-columns: 20px 1fr;
                            gap: 6px;
                        }

                        .check {
                            width: 100%;
                        }

                        &Text {
                            color: $white;
                            font-weight: 500;
                        }

                        &.hidden {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }
}

.banner {
    background: $dark;
    margin-top: 60px;
    display: grid;
    grid-template-columns: 608px minmax(300px, 749px);
    justify-content: space-between;
    align-items: center;
    gap: 60px;
    padding: 70px 40px;
    border-radius: 40px;

    @media (max-width: $md1+px) {
        padding: 60px 30px;
        grid-template-columns: 500px minmax(200px, 749px);
    }

    @media (max-width: $md3+px) {
        gap: 40px;
    }

    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        align-items: unset;
        margin-top: 40px;
    }

    @media (max-width: $md5+px) {
        border-radius: 30px;
    }

    @media (max-width: $md6+px) {
        border-radius: 20px;
        padding: 28px 20px;
        gap: 28px;
        margin-top: 28px;
    }

    .title {
        line-height: 130%;
        color: $white;

        @media (max-width: $md4+px) {
            font-size: 32px;
            text-align: center;
        }

        @media (max-width: $md6+px) {
            font-size: 24px;
            text-align: left;
        }
    }

    .image {
        width: 100%;
        max-width: 749px;
        display: flex;
        margin: 0 auto;

        @media (max-width: $md4+px) {
            max-width: 600px;
        }
    }
}