@import '../../../styles/var';

.wrap {
    position: relative;
    border-radius: 40px;
    background: $darkgreen;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;   
        gap: 30px 10px;
    }

    @media (max-width: $md4+px) {
        padding: 30px;
    }

    @media (max-width: $md6+px) {
        padding: 28px 30px;
    }

    .image {
        height: 68px;

        @media (max-width: $md1+px) {
            height: 56px;
        }

        @media (max-width: $md6+px) {
            height: 52px;
        }
    }
}