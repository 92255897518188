@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        text-align: center;
        max-width: 900px;
        margin: 24px auto 48px;

        @media (max-width: $md1+px) {
            max-width: 750px;
        }

        @media (max-width: $md4+px) {
            max-width: 560px;
            margin-bottom: 35px;
        }

        @media (max-width: $md6+px) {
            margin: 12px 0 28px;
        }
    }

    .body {
        display: grid;
        grid-template-columns: 619px minmax(300px, 855px);
        justify-content: space-between;
        gap: 30px;

        @media (max-width: $md1+px) {
            grid-template-columns: 550px minmax(300px, 855px);
            align-items: flex-end;
        }

        @media (max-width: $md3+px) {
            grid-template-columns: 520px minmax(300px, 855px);
        }

        @media (max-width: $md4+px) {
            display: flex;
            flex-direction: column;
        }

        @media (max-width: $md6+px) {
            gap: 20px;
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 40px;

            @media (max-width: $md6+px) {
                gap: 28px;
            }

            .text {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }

            .cards {
                border-radius: 40px;
                background: $darkgreen;
                display: flex;
                align-items: center;
                gap: 20px;
                justify-content: space-between;
                padding: 30px;

                @media (max-width: 600px) {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                }

                @media (max-width: $md6+px) {
                    padding: 24px 20px;
                    gap: 16px;
                }

                .card {

                    .subtitle {

                    }
                    
                    .desc {
                        margin-top: 8px;
                        color: $lime;
                    }
                }
            }
        }

        .image {
            width: 100%;
            max-width: 855px;

            @media (max-width: $md3+px) {
                max-width: 500px;
                display: flex;
                margin: 0 auto;
            }

            img {
                width: 100%;
            }
        }
    }
}