@import '../../../styles/var';

.heading {

    &.center {
        display: flex;
        justify-content: center;
    }

    .body {
        position: relative;
        display: inline-flex;
        padding: 12px 24px;
        border-radius: 200px;
        background: #29320E;
        font-family: $inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 100%;
        text-transform: uppercase;
    
        @media (max-width: $md6+px) {
            font-size: 14px;
            padding: 8px 16px;
        }
    
        span {
            background: linear-gradient(90deg, #CBFB45 -8.47%, #30FBD5 107.92%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    
        &.center {
            margin: auto;
            justify-content: center;
            display: flex;
            align-items: center;
        }
    }
}