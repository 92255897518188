@import '../../../styles/var';

.wrap {
    position: relative;

    .item {
        display: flex;
        align-items: center;
        gap: 60px;
        padding-right: 60px;

        @media (max-width: $md4+px) {
            gap: 40px;
            padding-right: 40px;
        }

        @media (max-width: $md6+px) {
            gap: 30px;
            padding-right: 30px;
        }

        .icon {
            height: 32px;

            @media (max-width: $md6+px) {
                height: 28px;
            }
        }

        .star {
            width: 40px;

            @media (max-width: $md4+px) {
                width: 32px;
            }

            @media (max-width: $md6+px) {
                width: 24px;
            }
        }
    }
}