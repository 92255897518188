$inter: "Inter";

$minwidth: 375px;
$mw: 1620;
$md1: $mw + 30;
$md2: 1420;
$md3: 1220;
$md4: 992;
$md5: 768;
$md6: 480;

$lime: #CBFB45;
$grey: #C1C1C1;
$white: #FCFFF1;
$dark: #030301;
$darkgreen: #29320E;
$bgcard: #26262A;