@import '../../../styles/var';

.wrap {
    position: relative;

    .head {
        display: grid;
        grid-template-columns: 600px minmax(300px, 720px);
        gap: 60px;

        @media (max-width: $md1+px) {
            grid-template-columns: 510px minmax(300px, 720px);
        }

        @media (max-width: $md3+px) {
            grid-template-columns: 400px 1fr;
        }

        @media (max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 35px;
        }

        @media (max-width: $md6+px) {
            gap: 28px;
        }

        .title {
            @media (max-width: $md6+px) {
                max-width: 330px;
            }
        }

        .text {}
    }

    .cards {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 4px;

        @media (max-width: $md4+px) {
            margin-top: 35px;
        }

        @media (max-width: $md5+px) {
            margin-top: 28px;
        }

        .card {
            border-radius: 40px;
            background: $darkgreen;
            padding: 40px;
            display: grid;
            grid-template-columns: 489px 1fr;
            gap: 123px;

            @media (max-width: $md1+px) {
                gap: 80px;
            }

            @media (max-width: $md3+px) {
                padding: 30px;
                grid-template-columns: 400px 1fr;
            }

            @media (max-width: $md4+px) {
                grid-template-columns: 350px 1fr;
                gap: 40px;
            }

            @media (max-width: 900px) {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }

            @media (max-width: $md5+px) {
                border-radius: 30px;
            }

            @media (max-width: $md6+px) {
                border-radius: 20px;
                padding: 24px 20px;
                gap: 16px;
            }

            .subtitle {
                color: $lime;
                line-height: 120%;
            }

            .desc {

            }
        }
    }
}