@import '../../../styles/var';

.wrap {
    position: relative;
    background: $darkgreen;
    padding: 70px 0;

    @media (max-width: $md4+px) {
        padding: 60px 0;
    }

    @media (max-width: $md6+px) {
        padding: 36px 0;
    }

    .cards {
        display: flex;
        gap: 16px;
        padding-right: 16px;

        @media (max-width: $md6+px) {
            gap: 12px;
            padding-right: 12px;
        }

        .card {
            border-radius: 40px;
            width: 374px;
            height: 240px;
            padding: 36px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media (max-width: $md4+px) {
                width: 320px;
                height: 220px;
            }

            @media (max-width: $md5+px) {
                border-radius: 30px;
            }

            @media (max-width: $md6+px) {
                border-radius: 20px;
                width: 200px;
                height: 130px;
                padding: 20px;
            }

            .head {
                display: grid;
                grid-template-columns: 1fr 60px;
                align-items: center;
                gap: 30px;

                @media (max-width: $md4+px) {
                    grid-template-columns: 1fr 50px;
                }

                @media (max-width: $md6+px) {
                    grid-template-columns: 1fr 32px;
                    gap: 10px;
                }

                .info {

                    .name {}

                    .ticker {
                        margin-top: 12px;
                        color: $white;

                        @media (max-width: $md6+px) {
                            font-size: 10px;
                            margin-top: 6px;
                        }
                    }
                }

                .icon {
                    width: 100%;
                }
            }

            .bottom {

                .year {
                    color: $white;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 100%;

                    @media (max-width: $md6+px) {
                        font-size: 10px;
                    }
                }

                .percent {
                    margin-top: 8px;
                }
            }
        }
    }
}