@import '../../../styles/var';

.wrap {
    position: relative;

    .title {
        text-align: center;
        margin: 12px auto 48px;
        max-width: 650px;

        @media (max-width: $md4+px) {
            margin-bottom: 35px;
        }

        @media (max-width: $md6+px) {
            margin-bottom: 28px;
        }
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;
        gap: 30px;

        @media (max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }

        @media (max-width: $md6+px) {
            gap: 24px;
        }

        .card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 24px;

            @media (max-width: $md4+px) {
                gap: 16px;
            }

            @media (max-width: $md6+px) {
                gap: 12px;
            }

            .content {
                .num {
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    width: 92px;
                    aspect-ratio: 1/1;
                    background: $bgcard;
                    border-radius: 100%;
                    color: $lime;

                    @media (max-width: $md1+px) {
                        width: 82px;
                    }

                    @media (max-width: $md4+px) {
                        font-size: 48px !important;
                        width: 80px;
                    }
                }

                .subtitle {
                    margin: 32px 0 16px;

                    @media (max-width: $md4+px) {
                        margin: 24px 0 14px;
                    }

                    @media (max-width: $md6+px) {
                        margin: 16px 0 12px;
                    }
                }

                .text {
                    max-width: 275px;
                    margin: 0 auto;

                    @media (max-width: $md1+px) {
                        max-width: 250px;
                    }
                }
            }

            .info {
                text-decoration: none;
                color: $lime;
                display: inline-flex;

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}