@import "./_var.scss";
@import './mixins';

body,
html {
    background: #050501;
    color: $grey;

    &.lock {
        overflow: hidden;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: #FFF;
    }

    ::-webkit-scrollbar-thumb {
        background: $white;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: $white;
    }
}

.mt {
    margin-top: 140px;

    @media (max-width: $md4+px) {
        margin-top: 100px;
    }

    @media (max-width: $md6+px) {
        margin-top: 72px;
    }
}

.mt-btn {
    margin-top: 50px;

    @media(max-width: $md4+px) {
        margin-top: 40px;
    }
}

.txt-container {
    display: flex;
    flex-direction: column;
    gap: 25px;

    @media (max-width: $md4+px) {
        gap: 20px;
    }
}

.ovf-hidden {
    overflow: hidden;
}

.ovf-padding {
    padding-right: 5px;

    @media(max-width: $md3+px) {
        padding: 0;
    }
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}